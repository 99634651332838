import { conf } from 'config/env';
import * as authMapper from 'mappers/auth';
import { tokenTypes } from 'constants/auth';
import { post, put } from './index';

const HTTP_RESPONSE_NOT_OK = new Error('http response not ok');

export const verifyToken = async ({ tokenType, token }) => {
  let response;
  try {
    response = await fetch(`${conf.apiV1}/tokens/verify?${authMapper.verifyToken.toApi({ tokenType, token })}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include'
    });
    const rawResponse = await response.json();
    if (!response.ok && !rawResponse.error_code) throw HTTP_RESPONSE_NOT_OK;
    return authMapper.verifyToken.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    return { error: 'unknown' };
  }
};

export const activateAccount = async ({ password, passwordConfirmation, token }) => {
  let response;
  try {
    response = await fetch(`${conf.apiV1}/users/activation`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        password,
        password_confirmation: passwordConfirmation,
        [tokenTypes.ACTIVATION]: token,
      }),
    });
    const rawResponse = await response.json();
    if (!response.ok && !rawResponse.error_code) throw HTTP_RESPONSE_NOT_OK;
    return authMapper.activateAccount.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    return { error: 'unknown' }
  }
};

export const resetPassword = async ({ password, passwordConfirmation, token }) => {
  let response;
  try {
    response = await fetch(`${conf.apiV1}/users/password`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        password,
        password_confirmation: passwordConfirmation,
        [tokenTypes.RESET_PASSWORD]: token,
      }),
    });
    const rawResponse = await response.json();
    if (!response.ok && !rawResponse.error_code) throw HTTP_RESPONSE_NOT_OK;
    return authMapper.resetPassword.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    return { error: 'unknown' }
  }
};

export const confirmAccount = async ({ token }) => {
  let response;
  try {
    response = await fetch(`${conf.apiV1}/users/confirmation?${authMapper.confirmAccount.toApi({ token })}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
      },
      credentials: 'include'
    });
    const rawResponse = await response.json();
    if (!response.ok && !rawResponse.error_code) throw HTTP_RESPONSE_NOT_OK;
    return authMapper.confirmAccount.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    return { error: 'unknown' }
  }
};

export const login = async ({ email, password, otp_user_id, otp_attempt, fingerprint }) => {
  let response;
  try {
    response = await fetch(`${conf.apiV1}/users/login`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        fingerprint,
        user:
          otp_user_id && otp_attempt
            ? { otp_user_id, otp_attempt }
            : {
                email: String(email)?.trim(),
                password: String(password)?.trim(),
              },
      }),
    });
    const rawResponse = await response.json();
    if (!response.ok && !rawResponse.error_code) throw HTTP_RESPONSE_NOT_OK;
    return authMapper.login.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    if (response?.status === 401) return { error: 'invalidEmailPassword' };
    return { error: 'unknown' }
  }
};

export const setupDoubleAuthentication = async ({ method, phoneNumber, active, otp_user_id }) =>
  put({
    endpoint: 'v1/users/update_two_factor',
    catchErrors: [422],
    params: {
      method,
      phone: phoneNumber,
      active,
      otp_user_id
    },
    debug: {},
  });

export const validateDoubleAuthentication = async ({ otp_attempt }) =>
  put({
    endpoint: 'v1/users/validate_otp_code',
    catchErrors: [422],
    params: { otp_attempt },
    debug: {},
  });

export const requestPasswordReset = async ({ email }) => {
  let response;
  try {
    response = await fetch(`${conf.apiV1}/users/password`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({
        email: String(email)?.trim(),
      }),
    });
    const rawResponse = await response.json();
    if (!response.ok && !rawResponse.error_code) throw HTTP_RESPONSE_NOT_OK;
    return authMapper.requestPasswordReset.fromApi(rawResponse);
  } catch (err) {
    if (response.ok) return {}; // in case API returns empty response body
    if (response?.status === 401) return { error: 'invalidEmail' };
    return { error: 'unknown' }
  }
};

export const requestRegister = params => post({
  endpoint: `v1/users/register/contact`,
  catchErrors: [422],
  withCredentials: true,
  params,
  debug: {},
});
