import * as mapper from 'mappers/campaigns';
import { mapInteractionDetails } from 'mappers/posts';

import { get, post, put, remove } from './index';


export const createNewCampaign = query => post({
  endpoint: `influence/projects`,
  params: mapper.createNewCampaign.toApi(query),
  catchErrors: [422],
});

/* ********
 *
 * ULTIME BATCH
 */

export const addProfilesToCampaigns = ({ campaignIds, profileIds }) => post({
  endpoint: `influence/projects/profiles/batch`,
  params: mapper.handleProfilesToCampaigns.toApi({ profileIds, campaignIds }),
  catchErrors: [422],
  debug: true,
});

export const removeProfilesToCampaigns = ({ campaignIds, profileIds }) => remove({
  endpoint: `influence/projects/profiles/batch`,
  params: mapper.handleProfilesToCampaigns.toApi({ profileIds, campaignIds }),
  debug: true,
});

export const updateCampaign = ({campaignId, ...datas}) => put({
  endpoint: `influence/projects/${campaignId}`,
  params: mapper.updateCampaign.toApi(datas),
  debug: true,
});

export const updateCampaignWatchingStatus = ({campaignId, isWatching}) => put({
  endpoint: `influence/projects/${campaignId}`,
  params: {
    project: {
      is_social_listening_active: isWatching
    }
  },
  debug: true,
});

export const removeCampaign = campaignId => remove({
  endpoint: `influence/projects/${campaignId}`,
  debug: true,
});

export const archiveCampaign = campaignId => post({
  endpoint: `influence/projects/${campaignId}/archive`,
  debug: true,
});

export const updateCampaignsStatus = ({ ids, newState }) => post({
  endpoint: `influence/projects/batch_status`,
  params: {
    ids,
    status: newState
  },
  debug: true,
});

export const duplicateCampaign = campaignId => post({
  endpoint: `influence/projects/${campaignId}/duplicate`,
  debug: true,
})

export const getCampaignDetails = ({ campaignId }) => get({
  endpoint: `influence/projects/${campaignId}`,
  debug: true,
});

export const getCampaignProfiles = ({ campaignId, params }) => get({
  endpoint: `influence/v2/projects/${campaignId}/profiles`,
  params,
  debug: true,
});

export const getPublicCampaignDetails = token => get({
  endpoint: `influence/projects/share`,
  catchErrors: [404],
  params: { token },
});

export const getCampaignIdFromToken = (token) =>
  get({
    endpoint: `influence/v2/castings/${token}`,
    catchErrors: [404],
    debug: true,
  });

export const updateProfileState = ({campaignId, profileId, state}) => put({
  endpoint: `influence/projects/${campaignId}/profiles/${profileId}/update_status`,
  params: mapper.updateProfileState.toApi({state}),
  debug: true,
  catchErrors: [404],
});

export const updateProfilesDelivery = ({campaignId, profilesIds, delivery}) => put({
  endpoint: `influence/projects/${campaignId}/update_deliveries`,
  params: {
    profiles_ids: profilesIds,
    delivery: mapper.updateProfileDelivery.toApi({delivery}),
  },
  catchErrors: [422],
  catchNoAnswer: true,
});

export const getReporting = ({ campaignId, debug, ...query }) => post({
  endpoint: `influence/projects/${campaignId}/reporting`,
  params: mapper.getReporting.toApi(query),
  noRetry: true,
  timeout: 180000, // 3 min
});

export const getReportingProfileTags = ({ campaignId }) => get({
  endpoint: `influence/projects/${campaignId}/reporting/profile_tags`,
});

export const getDiscountCodes = ({ campaignId, params }) => get({
  endpoint: `influence/v2/projects/${campaignId}/discount_codes`,
  params,
  debug: {}
});

export const exportReportingCsv = ({campaignId, ...query}) => post({
  endpoint: `influence/projects/${campaignId}/export`,
  params: mapper.exportReporting.toApi(query),
  debug: {
    error : true,
    status : 422
  },
  catchErrors : [422]
});

export const exportReportingPptx = ({ campaignId, isCustom }) => post({
  endpoint: `influence/v2/projects/${campaignId}/reporting/generate_slide_report`,
  params: {
    custom_template: Boolean(isCustom),
  },
})

export const exportCastingPptx = ({campaignId}) => post({
  endpoint: `influence/v2/projects/${campaignId}/profiles/generate_slide_export`,
  debug: true,
})

export const exportReportingMedias = ({campaignId, ...query}) => post({
  endpoint: `influence/projects/${campaignId}/posts/export`,
  params: mapper.exportReporting.toApi(query),
  catchErrors: [422],
  debug: {
    error: true,
    status: 422
  },
});

export const getProfilePosts = ({ campaignId, debug, ...query }) => get({
  endpoint: `influence/projects/${campaignId}/posts/search`,
  params: mapper.getProfilePosts.toApi(query),
});

export const addPostsToReporting = ({ campaignId, posts }) => put({
  endpoint: `influence/projects/${campaignId}/posts_configuration`,
  params: mapper.addPostsToReporting.toApi(posts),
  debug: true,
});

export const generateShareToken = campaignId => post({
  endpoint: `influence/projects/${campaignId}/share`,
  debug: {
    share_token: 'abcdefghijklmnopqrstuvwxzy.0123456789',
  },
});

export const uploadLogo = ({ campaignId, ...query }) => put({
  endpoint: `influence/projects/${campaignId}/share`,
  params: mapper.uploadLogo.toApi(query),
  debug: true,
  catchErrors: [422], // 422: file-invalid-type
});

export const disableShareLink = campaignId => remove({
  endpoint: `influence/projects/${campaignId}/share`,
  debug: true,
});

export const removePostFromReporting = ({ campaignId, postsIds }) => put({
  endpoint: `influence/projects/${campaignId}/posts_configuration`,
  params: mapper.removePostFromReporting.toApi(postsIds),
  debug: true,
});

export const getCampaignUsersRoles = campaignId => get({
  endpoint: `influence/projects/${campaignId}/collaborations`,
});

export const setCampaignIsPrivate = ({campaignId, isPrivate}) => put({
  endpoint: `influence/projects/${campaignId}`,
  params: mapper.setCampaignIsPrivate.toApi(isPrivate),
  debug: true,
});

export const setCampaignUserRole = ({campaignId, userId, role}) => put({
  endpoint: `influence/projects/${campaignId}/collaborations/${userId}`,
  params: mapper.setCampaignUserRole.toApi(role),
  debug: true,
});

export const getCampaignsByUser = ({ userId, page, perPage, searchKey }) => get({
  endpoint: `influence/contacts/${userId}/projects`,
  params: {
    search: searchKey || '',
    page: page || 0,
    per_page: perPage
  },
});

export const saveManualInteractions = ({ campaignId, postId, interactions, isForced }) => post({
  endpoint: `influence/projects/${campaignId}/post_stats`,
  params: {
    post_id: postId,
    post_stat: mapInteractionDetails.toApi(interactions, isForced)
  },
});

export const updateManualInteractions = ({ campaignId, postId, interactions, isForced }) => put({
  endpoint: `influence/projects/${campaignId}/post_stats/${postId}`,
  params: {
    post_stat: mapInteractionDetails.toApi(interactions, isForced)
  },
  debug: true,
});

export const aggregateCampaigns = (params) => post({
  endpoint: `influence/v2/aggregate_datas`,
  params,
  debug: true,
});

/* ********
 *
 * CONTRACT
 */

export const uploadDocuments = ({ campaignId, kolId, data }) => post({
  endpoint: `influence/v2/projects/${campaignId}/contracts`,
  params: {
    public_profile_id: kolId,
    data
  },
  debug: true,
})

export const getKolDocuments = ({ campaignId, kolId }) => get({
  endpoint: `influence/v2/projects/${campaignId}/contracts`,
  params: {
    public_profile_id: kolId,
  },
  debug: true,
  catchErrors: [404],
})

export const deleteKolDocuments = ({ id }) => remove({
  endpoint: `influence/v2/contracts/${id}`,
  debug: true,
})

/* ********
 *
 * FORECAST
 */
export const getForecastFollowers = ({ campaignId, network, profilesIds }) => post({
  endpoint: `influence/v2/projects/${campaignId}/forecast/${network}`,
  params: {
    profile_ids: profilesIds,
  },
  debug: true,
  catchErrors: [500],
})

export const getForecastStats = ({ campaignId, network, profilesIds, publicationType }) => post({
  endpoint: `influence/v2/projects/${campaignId}/forecast/${network}/${publicationType}`,
  params: {
    profile_ids: profilesIds,
  },
  debug: true,
  catchErrors: [500],
})


export const getPropositionLogs = ({ campaignId, profileId, params = {} }) =>
  get({
    endpoint: `influence/v2/projects/${campaignId}/profiles/${profileId}/proposition_logs`,
    params,
    debug: true,
  });

export const addPropositionLog = ({ comment, profileId, campaignId }) =>
  post({
    endpoint: `influence/v2/projects/${campaignId}/profiles/${profileId}/proposition_logs`,
    params: {
      proposition_log: {
        comment,
      },
    },
    debug: true,
  });

  export const shareCastingValidation = ({
    user_ids,
    public_sharing,
    campaignId,
  }) =>
    post({
      endpoint: `influence/v2/projects/${campaignId}/castings`,
      params: {
        user_ids,
        public_sharing,
      },
      debug: true,
    });

  export const saveGuestUser = ({ guest, castingId }) =>
    post({
      endpoint: `influence/v2/castings/${castingId}/guests`,
      params: {
        guest: {
          first_name: guest.first_name,
          last_name: guest.last_name,
          email: guest.email,
        },
      },
      debug: true,
    });

export const addPostsByURL = ({ campaignId, urls }) =>
  post({
    endpoint: `influence/v2/projects/${campaignId}/contents`,
    params: {
      content: {
        urls,
      },
    },
    debug: true,
    catchErrors: [400],
  });