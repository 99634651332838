import React, { memo, useMemo, useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigationType,
  NavigationType,
} from 'react-router-dom';
import cn from 'classnames';
import moment from 'moment';
import { FullStory } from '@fullstory/browser';
import { UAParser } from 'ua-parser-js';
import Loadable from 'react-loadable';
import { ProductFruits } from 'react-product-fruits';

import Loader from 'kolkit/Loader';

import { updateKey } from 'slices/search/searchEngine.slice';
import { setViewport } from 'actions/env';
import { setDevice } from 'actions/ui';
import { conf, env, ENV_PROD } from 'config/env';
import routes, { getRedirectUrl } from 'config/routes';
import * as featureNames from 'constants/feature-names';
import { MountRouteByFeature, isFeatureActive } from 'utils/feature-toggle';

import { Navbar } from 'components/modules';
import { useDispatch, useSelector } from 'utils/redux';
import useWindowSizeObserver from 'utils/hooks/useWindowSizeObserver';
import useLoading from 'utils/hooks/useLoading';
import useViews from 'utils/hooks/useViews';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import useTrackingLogin from 'utils/hooks/useTrackingLogin';

import EntityDispatcher from './dispatchers/ProfileDetails';
import FreePlaceholder from './loaders/FreePlaceholder';
import AnnouncementBanner from './CustomerAnnouncements/AnnouncementBanner';
import AsyncTasksListener from './modules/AsyncTasksListener';
import DebugPage from './DebugPage';
import SatisMeter from '../utils/SatisMeter';

const SearchEngine = Loadable({
  loader: () => import('components/search/SearchEngine/SearchEngine'),
  loading: () => <Loader full />,
});

const ContentSearch = Loadable({
  loader: () => import('components/search/ContentSearch/ContentSearch'),
  loading:  () => <Loader full />,
});

const Engine = Loadable({
  loader: () => import('components/engine/Engine'),
  loading: () => <Loader full />,
});

const CampaignsList = Loadable({
  loader: () => import('components/campaigns/CampaignsList/CampaignsList'),
  loading:  () => <Loader full />,
});

const Messaging = Loadable({
  loader: () => import('components/messaging/Loader'),
  loading:  () => <Loader full />,
});

const CampaignDetails = Loadable({
  loader: () => import('components/campaigns/CampaignDetails/CampaignDetails'),
  loading:  () => <Loader full />,
});

const MyInfluencers = Loadable({
  loader: () => import('components/myInfluencers/MyInfluencers'),
  loading:  () => <Loader full />,
});

const Modals = Loadable({
  loader: () => import('components/modules/Modal/Modals'),
  loading: () => null,
});

const Modales = Loadable({
  loader: () => import('components/ui/Modales'),
  loading: () => null,
});

const ZendeskRedirect = Loadable({
  loader: () => import('components/zendesk/ZendeskRedirect'),
  loading: () => null,
});

const ZendeskScript = Loadable({
  loader: () => import('components/zendesk/ZendeskScript'),
  loading: () => null,
});

const Dashboard = Loadable({
  loader: () => import('components/dashboard/Dashboard'),
  loading:  () => <Loader full />,
});

const ListeningDetails = Loadable({
  loader: () => import('components/listeningV2/ListeningDetails'),
  loading:  () => <Loader full />,
});

const SettingsPage = Loadable({
  loader: () => import('components/account/SettingsPage'),
  loading:  () => <Loader full />,
});


const Structure = () => {
  const dispatch = useDispatch();
  const dimensions = useWindowSizeObserver();
  const { isListeningPage } = useViews();
  const [isNewVersionEnabled] = useLocalStorage('search.use-new-version', true);

  const { loading: globalLoader } = useLoading('globalLoader');
  const { loading: phraseLoader } = useLoading('phraseLoader');

  useTrackingLogin();

  const { locale, user } = useSelector(({ user, env }) => ({
    locale: env.locale,
    user: user.profile,
  }));

  // Used to better handle profile details modal
  const location = useLocation();
  const navigationType = useNavigationType();

  const underProfileModalLocation = useMemo(
    () => {
    // To know if it's in a new tab or from a navigation event
    // https://github.com/remix-run/history/blob/main/docs/api-reference.md#location.key
      const isNewTab = location.key === 'default';
      const isRefreshedPage = navigationType === NavigationType.Pop;
      const locationMatchProfile =
        location.pathname.match(
          // For campaign URLs (we don't add /profiles/ID to the URL)
          new RegExp(`(/${routes.campaigns}/\\d+/(?!${routes.messaging})[\\w-]+/)\\d+$`),
        ) || // For other URLs with /profiles/ID
        location.pathname.match(new RegExp(`(.+)?/${routes.profile}/\\d+$`));

      if (!locationMatchProfile) return location;
      if (isNewTab || isRefreshedPage) return undefined;
      return { ...location, pathname: locationMatchProfile?.[1] || '/' };
    },
    [location, navigationType]
  );

  const initDataProfile = useMemo(
    () => ({
      fullStory: {
        uid: user?.email,
        properties: {
          // email and displayName are specific to FullStory
          email: user?.email,
          displayName: user?.email,
          userId: user?.id,
          teamId: user?.teamId,
          teamName: user?.teamName,
          firstName: user?.firstName,
          lastName: user?.lastName,
          companyName: user?.companyName,
          accountType: user?.accountType,
          ...user?.licences?.reduce(
            (acc, licence, index) => ({
              ...acc,
              [`licence_${index + 1}`]: licence.name,
            }),
            {},
          ),
        },
      },
      productFruit: {
        username: user?.id, // REQUIRED - any unique user identifier
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
        signUpAt: user?.createdAt,
        props: { // custom infos
          teamId: user?.teamId,
          teamName: user?.teamName,
          userId: user?.id,
          companyName: user?.companyName,
          accountType: user?.accountType,
          ...user?.licences?.reduce(
            (acc, licence, index) => ({
              ...acc,
              [`licence_${index + 1}`]: licence.name,
            }),
            {},
          ),
        }
      }
    }),
    [user]
  );

  useEffect(() => {
		const uaParser = new UAParser();
		const { type } = uaParser.getDevice();
    dispatch(setViewport(dimensions));
    dispatch(
			setDevice(type === 'mobile' || type === 'tablet' ? 'mobile' : 'desktop'),
		);
    // eslint-disable-next-line
  }, [dimensions]);

  useEffect(() => {
    moment.locale(locale?.toLowerCase());
  }, [locale]);

  useEffect(() => {
    dispatch(updateKey({ key: 'enabled', data: isNewVersionEnabled }));
    // eslint-disable-next-line
  }, [isNewVersionEnabled]);

  useEffect(() => {
    FullStory('setIdentity', initDataProfile.fullStory);
    // eslint-disable-next-line
  }, [])

  const renderRoutes = useMemo(() => {
    if (!isFeatureActive(featureNames.INFLUENCE_CORE))
      return (
        <Routes location={underProfileModalLocation}>
          <Route path={`/${routes.dashboard}/*`} element={<Dashboard />} />
          {/* Listening routes */}
          <Route path={`/${routes.listening}/:monitoringId`}>
            <Route path=":startDate/:endDate" element={<ListeningDetails />} />
            <Route path=":startDate" element={<ListeningDetails />} />
            <Route path="" element={<ListeningDetails />} />
          </Route>
          <Route path={`/${routes.zendesk}/*`} element={<ZendeskRedirect />} />
          <Route path={`/${routes.myAccount}/*`} element={<SettingsPage />} />
          <Route path="/" element={<FreePlaceholder />} />
          {underProfileModalLocation && (
            <Route path="*" element={<Navigate to="/" />} replace />
          )}
        </Routes>
      );

    const redirectUrl = getRedirectUrl();

    return (
      <>
        {underProfileModalLocation && (
        <Routes location={underProfileModalLocation}>
          {/* Engine routes */}
          <Route path="/" element={<MountRouteByFeature featureName={featureNames.SEARCH_ENGINE} redirectPath={redirectUrl} />}>
            <Route
              index
              element={isNewVersionEnabled ? <SearchEngine /> : <Engine />}
            />
          </Route>

          {/* Content search routes */}
          <Route path={`/${routes.contentSearch}`} element={<MountRouteByFeature featureName={featureNames.SEARCH_ENGINE} onlyForCompanyZZZ />}>
            <Route index element={<ContentSearch />} />
          </Route>

          {/* Campaigns routes */}
          <Route path={`/${routes.campaigns}`} element={<MountRouteByFeature featureName={featureNames.MANAGE_CAMPAIGN} />}>
            <Route index element={<CampaignsList />} />
            <Route path=":campaignId/*" element={<CampaignDetails />} />
          </Route>

          {/* Messaging routes */}
          <Route path={`/${routes.messaging}`} element={<MountRouteByFeature featureName={featureNames.MANAGE_CAMPAIGN} />}>
            <Route index element={<Messaging />} />
            <Route path=":conversationId" element={<Messaging />} />
          </Route>

          {/* Dashboard */}
          <Route path={`/${routes.dashboard}/*`} element={<Dashboard />} />

          {/* Listening routes */}
          <Route
            path={`/${routes.listening}/:monitoringId`}
            element={
              <MountRouteByFeature
                allowForAdmins
                featureName={featureNames.LISTENING}
              />
            }
          >
            <Route
              path=":startDate/:endDate"
              element={<ListeningDetails />}
            />
            <Route path=":startDate" element={<ListeningDetails />} />
            <Route path="" element={<ListeningDetails />} />
          </Route>

          {/* MyKOLs routes */}
          <Route path={`/${routes.myKOLs}`} element={<MountRouteByFeature featureName={{any: [featureNames.FAVORITES, featureNames.LABELS]}} />}>
            <Route index element={<MyInfluencers />} />
            <Route path="*" element={<MyInfluencers />} />
          </Route>

          {/* Others */}
          <Route path={`/${routes.zendesk}/*`} element={<ZendeskRedirect />} />
          <Route path={`/${routes.myAccount}/*`} element={<SettingsPage />} />
          {env !== ENV_PROD && <Route path={`/${routes.debug}`} element={<DebugPage />} />}

          {/* No routes matched => Redirect */}
          <Route path="*" element={<Navigate replace to={redirectUrl} />} />
        </Routes>
        )}
        <Routes>
          <Route path={`/${routes.profile}/:pId`} element={<EntityDispatcher />} />
          <Route path={`/${routes.contentSearch}/${routes.profile}/:pId`} element={<EntityDispatcher />} />

          {/* Campaigns routes */}
          <Route path={`/${routes.campaigns}`} element={<MountRouteByFeature featureName={featureNames.MANAGE_CAMPAIGN} />}>
            <Route path={`:campaignId/${routes.profile}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.comparison}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.discountCode}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.trackingLinks}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.reporting}/:pId`} element={<EntityDispatcher />} />
            <Route path={`:campaignId/${routes.messaging}/:conversationId/${routes.profile}/:pId`} element={<EntityDispatcher />} />
          </Route>

          {/* Messaging routes */}
          <Route path={`/${routes.messaging}`} element={<MountRouteByFeature featureName={featureNames.MANAGE_CAMPAIGN} />}>
            <Route path={`:conversationId/${routes.profile}/:pId`} element={<EntityDispatcher />} />
          </Route>

          {/* Listening routes */}
          <Route
            path={`/${routes.listening}/:monitoringId`}
            element={
              <MountRouteByFeature
                allowForAdmins
                featureName={featureNames.LISTENING}
              />
            }
          >
            <Route
              path={`:startDate/:endDate/${routes.profile}/:pId`}
              element={<EntityDispatcher />}
            />
          </Route>

          {/* MyKOLs routes */}
          <Route path={`/${routes.myKOLs}`} element={<MountRouteByFeature featureName={{any: [featureNames.FAVORITES, featureNames.LABELS]}} />}>
            <Route path={`${routes.manage}/${routes.profile}/:pId`} element={<EntityDispatcher />} />
            <Route path={`${routes.follow}/${routes.profile}/:pId`} element={<EntityDispatcher />} />
          </Route>
        </Routes>
      </>
    );
  }, [isNewVersionEnabled, underProfileModalLocation]);

  const loader = useMemo(() => (
    <Loader
      theme="navy"
      full
      position="center"
      background="rgba(255, 255, 255, .8)"
    />
  ), []);

  const cnContent = cn('main-content', {
    'grey-bg': isListeningPage,
  });

  const productFruitsLocale = useMemo(
    () => locale?.startsWith('fr') ? 'fr' : 'en',
    [locale]
  );

  return (
    <div className="loaded">
      {(globalLoader || phraseLoader) && loader}
      <Navbar />
      <Modales />
      <Modals />
      <SatisMeter />
      <ZendeskScript />

      {conf.productFruitsWorkspaceCode && !user?.admin && user?.id && (
        <ProductFruits
          key={productFruitsLocale}
          language={productFruitsLocale}
          workspaceCode={conf.productFruitsWorkspaceCode}
          user={initDataProfile.productFruit}
          lifeCycle="unmount"
        />
      )}

      <div className='main_container'>
        <AnnouncementBanner />
        <AsyncTasksListener />
        <div className="structure_scroll">
          <div className="scroll_content">
            <main className={cnContent}>
              {renderRoutes}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Structure);
