import React, { memo, useCallback, useMemo } from 'react';
import { toast as toastify } from 'react-toastify';
import { useIntl } from 'react-intl-phraseapp';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import toast from 'utils/toast';
import routes from 'config/routes';

import ErrorBoundary from 'components/modules/ErrorBoundary';

const isExternalLink = (href) => {
  try {
    const url = new URL(href);
    // External link: different from influence.kolsquare.com
    // Or: influence.kolsquare.com/zendesk (Zendesk redirection route)
    return (
      url.hostname !== window.location.hostname ||
      url.pathname.startsWith(`/${routes.zendesk}`)
    );
  } catch {
    return false;
  }
};

export const isNotNull = (v) => v && v.trim() !== '' && v.trim() !== '​\n';

const LinkRenderer: React.FC<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >
> = ({ href, children }) => {
  return isExternalLink(href) ? (
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <a href={href}>{children}</a>
  );
};

const Markdown = ({ children, className = '' }) => {
  const intl = useIntl();
  const content = useMemo(
    () => (Array.isArray(children) || isNotNull(children) ? children : ''),
    [children],
  );

  const parseContent = (toParse) => {
    let markdownParsed = toParse;
    const matchesUnderline = toParse.match(/\++.+?\++/g);
    if (matchesUnderline?.length) {
      matchesUnderline.forEach((mark) => {
        markdownParsed = markdownParsed.replaceAll(
          mark,
          `~~${mark.replaceAll('++', '').trim()}~~`,
        );
      });
    }
    return markdownParsed;
  };

  const handleMarkdownError = useCallback(
    (error) => {
      console.error('Markdown error :>>', error);
      // Let's keep track of this for now
      // logError(error, { handled: true });
      // It's probably an error due to unsupported browser, so show the toast
      if (!toastify.isActive('unsupportedBrowser')) {
        toast(intl.formatMessage({ id: 'unsupportedBrowser.description' }), {
          type: 'error',
          duration: false,
          toastId: 'unsupportedBrowser',
          title: intl.formatMessage({ id: 'unsupportedBrowser.title' }),
        });
      }
    },
    [intl],
  );

  const markdownParsed = Array.isArray(content)
    ? content.map(parseContent).join('\n')
    : parseContent(content);

  return (
    <span className={className}>
      <ErrorBoundary fallback={markdownParsed} onError={handleMarkdownError}>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            del: (props) => <ins>{props.children}</ins>,
            a: LinkRenderer,
          }}
        >
          {markdownParsed}
        </ReactMarkdown>
      </ErrorBoundary>
    </span>
  );
};

export default memo(Markdown);
